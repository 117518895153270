@import "./less/colors.less";
@import "./less/sizes.less";

html, body {
    zoom: 1;

    margin: 0px;
    padding: 0px;

    font-family: Helvetica, Arial, sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: @brand-white;

    background-color: @brand-black;
}

body {
    height: 100%;
    overflow-y: hidden;
}

h1 {
    font-size: @h1;
}

h2 {
    font-size: @h2;
}

h3 {
    font-size: @h3;
}

h5 {
    font-size: @h5;
}

p {
    font-size: @p;
}

* {
    box-sizing: border-box;
}

canvas {
    vertical-align: top;
}

:global(#root) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

:global(#middle-wrap) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 200px;
    bottom: 0;
    right: 0;

    h1 {
        font-size: 55px;
        position: relative;
        font-weight: normal;
        margin-bottom: 0;
    }

    h2 {
        font-size: 45px;
        font-weight: normal;
    }

    :global(#middle) {
        display: flex;
        max-width: 800px;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-family: monospace;
    }

    :global(#middle)>img {
        width: 500px;
        height: 500px;
    }

    :global(#logo) {
        position: fixed;
        top: 60px;
        right: 40px;
        width: 80px;
    }

    :global(#logo)>img {
        width: 100%;
    }

    span:global(.blue) {
        color: #0090ff;
    }

    span:global(.gray) {
        color: #525252;
    }



    :global(.loading):after {
        overflow: hidden;
        position: absolute;
        left: 100%;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis 1.8s infinite;
        animation: ellipsis 1.8s infinite;
        content: "";
    }

    :global(.wiggle) {
        animation: wiggle 5s ease infinite;
        animation-delay: 1s;
    }

    @keyframes ellipsis {
        0% {
            content: "";
        }

        25% {
            content: ".";
        }

        50% {
            content: "..";
        }

        75% {
            content: "...";
        }
    }

    @keyframes wiggle {

        2%,
        18% {
            transform: rotate(-0.5deg);
        }

        4%,
        16% {
            transform: rotate(1deg);
        }

        6%,
        10%,
        14% {
            transform: rotate(-2deg);
        }

        8%,
        12% {
            transform: rotate(2deg);
        }

        20% {
            transform: rotate(0deg);
        }
    }
}

:global(#stat-box) {
    z-index: 10;
    display: none;
    font-family: monospace;
    flex-direction: column;
    position: fixed;
    background-color: fade(white, 10%);
    bottom: 25px;
    font-size: 30px;
    right: 20px;
    border-radius: 5px;
    padding: 10px 20px;
}
