@import "../less/colors.less";
@import "../less/sizes.less";

.AppWrapper {
    position: relative;

    height: 100%;
    width: 100%;
}

.App {
    display: flex;
    flex-direction: column;

    position: absolute;
    z-index: 1;

    height: 100%;
    width: 100%;

    padding: 2 * @grid-cell 4 * @grid-cell;
    padding-bottom: 0;

    color: @brand-white;

    > main {
        flex: 1;
    }
}
