@import "../../less/colors.less";

.WithProgress {
    position: relative;
    width: 100px;
    height: 100px;
    padding: 2px;

    .Progress {
        position: absolute;
        width: 100px;
        height: 100px;
        top: 0;
        left: 0;

        clip: rect(0px, 100px, 100px, 50px);

        > .left, > .right {
            width: 100px;
            height: 100px;
            border: 5px solid @brand-gold;
            border-radius: 50%;
            position: absolute;
            clip: rect(0px, 50px, 100px, 0px);
        }
    }
}
