@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.WeighIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    > div {
        width: 800px;
        display: flex;
        flex-direction: column;
    }
}

.Instruction {
    display: flex;
    flex-direction: row;
    margin-bottom: @grid-cell * 7;

    opacity: 0.2;
    transition: .2s opacity ease-in-out;

    &:global(.active) {
        opacity: 1;
    }

    .Left {
        display: flex;
        flex-direction: column;
        width: 600px;
        padding-left: 60px;

        .Step {
            font-size: @h1 *0.8;
            font-weight: 300;
            color: @ux-bw-62;
        }

        .Header {
            font-size: @h1;
            font-weight: normal;
            margin-bottom: @grid-cell * 2;
        }

        .Description {
            font-size: @h2;
            padding-right: 10px;;
        }
    }

    .Right {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        .Icon {
            margin-bottom: @grid-cell;
        }

        // .IconText {
        //     width: 150px;
        //     font-size: @p * 0.75;
        //     margin-left: @grid-cell;
        // }
    }
}
