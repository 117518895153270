@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.Connect {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    .Code {
        font-size: @h3;
    }
}
