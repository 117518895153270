@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.Background {
    position: absolute;
    overflow: hidden;
    z-index: 0;

    height: 100%;
    width: 100%;

    background-color: @brand-black;

    .Border {
        z-index: 1000;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 15px solid white;
        transition: opacity 0.15s ease;

        opacity: 0;

        &.show {
            opacity: 1;
        }
    }
}
