@import "../../../less/sizes.less";
@import "../../../less/colors.less";

.Burger {
    position: relative;
    margin-left: 20px;

    > .IconWrapper {
        height: 22px;
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
            cursor: pointer;
        }

        > .Line {
            height: 2px;
            width: 100%;

            border-radius: 1px;

            background-color: @brand-white;
        }
    }

    > .MenuWrapper {
        position: absolute;
        top: 100%;
        right: 0;
        padding-top: 25px;

        opacity: 0;
        pointer-events: none;

        transition: .1s ease opacity;

        &:global(.show) {
            opacity: 1;
            pointer-events: initial;
        }

        > .Menu {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-width: 180px;
            background-color: @brand-white;
            padding: 10px 0;
            border-radius: 4px;

            > .Item {
                position: relative;
                padding: 5px 10px;
                width: 100%;
                padding: 5px 20px;
                padding-right: 40px;
                color: @brand-black;
                background-color: transparent;

                &:hover:not(:global(.active)) {
                    cursor: pointer;
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:global(.active) {
                    background-color: @brand-gold;
                    pointer-events: none;
                    font-weight: bold;
                }

                &:global(.disabled) {
                    opacity: 0.5;
                    pointer-events: none;
                }

                > p {
                    margin: 0;
                }
            }
        }
    }
}
